@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* ANIS CHTOUROU 2020 */

html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

/* ALL */

:root {
    --primary-color : #C13440;
    --light-primary-color: #DF3C4A;
    --dark-primary-color: #A50E0E;
    --light-gray: #E5E5E5;
    --dark-gray : #AFAFAF;
    --background-editor: #fafbfc ;
    --background-top: #e1e4e8;
}

.shadow-box {
    box-shadow: -1px 9px 7px -6px rgba(163,163,163,1);
}

.button-primary {
    background-color: #C13440;
    background-color: var(--primary-color);
    border-radius: 5px;
    border: 0;
    color: white;
    font-weight: bold;
    padding: .1rem 1.5rem;
    outline: none;
    text-decoration: none;
    text-align: center;
    padding: .8rem 1.5rem;
}

.button-primary:hover {
    cursor: pointer
}

main.home-container,
main.podcast-container,
main.articles-container,
main.commu-container,
main.contacts-container {
    padding: 0 5rem;
}

.subtitle {
    border-left: 5px solid #C13440;
    border-left: 5px solid var(--primary-color);
    padding: .5rem;
    margin-top: 3rem;
}

.subtitle h2 {
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    text-align: left!important;
}

input[type="text"], input[type="password"],textarea {
    outline: none;
    padding: .8rem 1rem;
    background-color: #E5E5E5;
    background-color: var(--light-gray);
    border-radius: 5px;
    border: 0;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
}

input[type="text"],input[type="password"] {
    width: 20%;
}

textarea {
    resize: none;
    width: 50%;
    height: 150px;
    box-sizing: border-box;
}

.content-bottom {
    margin-bottom: 100px;
}

.content-bottom .content-time {
    color: #AFAFAF;
    color: var(--dark-gray);
    text-align: right;
    font-style: italic;
}

.flag-container {
    display: flex;
    flex-wrap: wrap;
}

.flag-container .flag-box {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    background-color: #C13440;
    background-color: var(--primary-color);
    border-radius: 5px;
    height: 1.3rem;
    display: flex;
    align-items: center;
}

.flag-container .flag-box p {
    margin: 0;
    padding: 0 1.3rem;
    color: white;
    font-size: .8rem;
}

#page-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 70vh;
    font-weight: bold;
    font-style: italic;
}

#page-404 img {
    height: 150px;
    margin-bottom: 2rem;
}

/* TOPBAR */
header {
    width: 100%;
}

header .menu {
    display: none;
}

header .topbar-container {
    display: flex;
    align-items: center;
    padding: 1.5rem 5rem;
}

header .select {
    color: #C13440!important;
    color: var(--primary-color)!important;
}

header a:not(.logo-container) {
    text-decoration: none;
    color: black;
    padding: 0 1rem;
    font-weight: bold;
}
header a.logo-container { outline: none }
header img {
    height: 70px;
    -webkit-animation: logoTopbar 2s;
            animation: logoTopbar 2s;
}

/* MOBILE TOBAR */

.menu-container {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C13440;
    background-color: var(--primary-color)
}

.menu-container .menu-exit {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 4rem;
}

.menu-container .link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.menu-container a {
    color: white!important;
    margin: 1rem 0;
}

/* HOME */

main.home-container .main-info-container {
    height: 200px;
    width: 100%;
    background-color: #E5E5E5;
    background-color: var(--light-gray);
    background-image: url(/static/media/background_home_desktop.b12d14b8.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}



/* PODCASTS & ARTICLES */
.last-component-container {
    display: flex;
}

.last-component-container .button-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -- LIST */
.component-list-container {
    margin: 40px 0;
    display: flex;
    flex: 0 1 50%;
}

.last-component-container a p {
    margin: 0
}

a.component-list-container {
    text-decoration: none;
    color: black;
}

.component-list-container .img-container {
    height: 150px;
    max-width: 150px;
    width: 150px;
    flex: 1 1
}

.component-list-container .img-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.component-list-container .info-container {
    margin-left: 25px;
    flex: 1 1;
    padding-right: 2rem;
}

.component-list-container .date {
    color: #AFAFAF;
    color: var(--dark-gray);
    font-style: italic;
    font-weight: normal;
    margin: 0;
    font-size: .8rem;
}

.component-list-container h3 {
    margin: 0;
    font-weight: bold;
    font-style: italic;
    font-size: 1.5rem;
}

.component-list-container .content {
    margin-top: .5rem;
    font-size: .9rem;
    margin-bottom: .5rem;
}


/* PODCAST & ARTICLES ALL */

.podcast-container section, .articles-container section {
    display: flex;
    flex-wrap: wrap;
}

h3.subtitle-part {
    font-style: italic;
}

.details-container p {
    text-align: justify
}

.details-container h1 {
    font-style: italic;
    font-size: 1.5rem;
    margin-top: 50px;
}

.details-container a  {
    color: #A50E0E;
    color: var(--dark-primary-color);
    text-decoration: none;
}

.details-container a:hover {
    border-bottom: 1px solid #A50E0E;
    border-bottom: 1px solid var(--dark-primary-color);
}

/* COMMUNAUTE */
main.commu-container .main-info-container {
    height: 150px;
    width: 100%;
    background-color: #E5E5E5;
    background-color: var(--light-gray);
    background-image: url(/static/media/background_commu.9bc42884.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-decoration: none;
    color: white
}

main.commu-container .main-info-container hr {
    height: 40%;
    border: 1px solid white;
    margin: 0 2rem;
}

main.commu-container .main-info-container img {
    height: 30%;
}

#insta-logo {
    margin-right: 1rem;
}

.commu-container section {
    width: 100%;
}

/* --MEMBRES */
.members-container {
    width: 100%;
}

.members-container .member-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.members-container .member-box:first-child {
    margin-top: 2rem;
}

.members-container .member-box.right {
    flex-direction: row-reverse;
}

.members-container .member-box .img-container {
    height: 120px;
    width: 120px;
    max-width: 120px;
    flex: 1 1;
}

.members-container .member-box .img-container img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.members-container .text-container {
    flex :1 1;
    max-width: 50%;
}

.members-container .member-box.right .text-container {
    margin-right: 50px;
}

.members-container .member-box.left .text-container {
    margin-left: 50px;
}

.members-container .text-container h2 {
    text-align: center;
    margin-top: 0;
}

.members-container .text-container p{
    text-align: center;
    font-style: italic;
    font-size: .9rem;
}

/* CONTACTS */
.contacts-container .explanation {
    font-style: italic;
}

/* FORM */
.contacts-container .contacts-form form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.contacts-container .contacts-form .button-container {
    width: 50%;
}

.contacts-container .contacts-form .button-primary {
    width: 10rem;
    float: right;
    margin-right: 0;
}

/* PODCAST DETAILS */
main.details-container {
    width: 70%;
    margin: 0 auto;
}

main.details-container .img-container{
    height: 120px;
    width: 120px;
    max-width: 120px;
    margin: 0 auto;
}

main.details-container .img-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

main.details-container .img-container.article {
    width: 300px;
    max-width: 300px;
}

main.details-container .img-container.article img {
    border-radius: 5px;
}

main.details-container h2 {
    text-align: center;
    font-style: italic;
}

main.details-container .button-container {
    width: 100%;
    display: flex;
    margin: 30px 0;
    justify-content: center;
}

/* player */

main.details-container .player-container {
    background-color: #C13440;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .8rem 1.5rem ;
    flex-wrap: wrap;
    width: 90%;
    max-width: 400px;
}

audio {
    outline: none;
}

/* --COMMENTS */
.details-comment  textarea {
    width: 100%;
}
.details-comment .contacts-form form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.details-comment .contacts-form .button-container {
    width: 100%;
    display: block;
    margin: 0;
}

.details-comment .contacts-form .button-primary {
    width: 10rem;
    float: right;
    margin-right: 0;
}

.details-comment .comment-container {
    margin-top: 30px;
    position: relative;
    padding: .5rem 1rem;
}

.details-comment .comment-container .author {
    font-weight: bold;
    margin-bottom: 1.3rem;
}

.details-comment .comment-container .content {
    margin: 0;
    white-space: pre-line;
}

.details-comment .comment-container .date {
    margin: 1rem 0;
    text-align: right;
    font-style: italic;
    color: #AFAFAF;
    color: var(--dark-gray);
}

/* -- DEL COMMENT */
.details-comment .comment-container.admin >.del-comment {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.details-comment .comment-container.admin >.del-comment svg {
    opacity: 0;
}

.details-comment .comment-container.admin >.del-comment:hover svg {
    font-size: 1.5rem;
    opacity: 1;
}

.details-comment .comment-container.admin >.del-comment:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.171);
    transition: .2s;
    z-index: 5;
}


/* FOOTER */

footer {
    margin-top: 10rem;
}

/* ADMIN */
main.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 90vh;
}

main.login-container form {
    width: 20%;
}

main.login-container form input[type="text"], main.login-container form input[type="password"] {
    width: 100%;
    box-sizing: border-box;
}

main.login-container form button {
    float: right
}

/* SIDEBAR */
.sidebar {
    width: 15rem;
    background-color: #C13440;
    background-color: var(--primary-color);
    position: fixed;
    height: 100vh;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
}

.sidebar .sidebar-content {
    padding: .5rem 2rem;
}

.sidebar h1 {
    color: white;
    margin: .5rem 0;
}

.sidebar hr {
    border: 2px solid white;
    width: 70%;
}

.sidebar .sidebar-content .category-component {
    margin: 2rem 0;
}

.sidebar .sidebar-content .category-component .category-name {
    color: #AFAFAF;
    color: var(--dark-gray);
    margin: 0 0 .5rem 0;
}

.sidebar .sidebar-content .category-component a {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    margin-bottom: 1.2rem;
}

.sidebar .sidebar-content .category-component a p {
    margin: 0 .5rem;
}

/* SECTION ADMIN PART */
.admin-container .info-component {
    margin-left: 17rem;
    padding: 1.5rem 0;
}

.admin-container .info-component h2 {
    margin: 0;
}

/* --NEW USERS */
.info-component .new-users-container {
    margin-top: 30px;
    width: 20rem;
}

.info-component .new-users-container input {
    width: 100%;
    box-sizing: border-box;
}

.info-component  .role-selector {
    position: relative;
    width: 8rem;
}

.info-component .role-selector .selector-button {
    background-color: #E5E5E5;
    background-color: var(--light-gray);
    border-radius: 5px;
    border: 0;
    padding: .8rem 1rem;
}

.info-component .role-selector:hover {
    cursor: pointer;
}

.info-component .role-selector p{
    margin: 0;
    font-size: .8rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-component .role-selector p svg {
    margin-left: .5rem;
}

.info-component .role-selector li {
    list-style-type: none;
    background-color: #E5E5E5;
    background-color: var(--light-gray);
    border-radius: 5px;
    border: 0;
    padding: .8rem 1rem;
    font-size: .8rem;
    margin-top: .5rem;
}

.info-component .new-users-container .button-container {
    display: flex;
    justify-content: flex-end;
}

.info-component .new-users-container .button-primary {
    padding: .8rem 1rem;
    margin-top: 1rem;
    float: right;
}

/* --ADMIN LIST */
.info-component .admin-list-container h2 {
    font-style: italic;
    margin: 2rem 0 1rem;
}

.info-component .admin-list-container h2::first-letter {
    text-transform: capitalize;
}

.info-component .admin-list-container .users-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25rem;
    background: #E5E5E5;
    background: var(--light-gray);
    padding: .8rem 1.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.info-component .admin-list-container .users-container p {
    margin: 0;
}

.info-component .admin-list-container .users-container p.username {
    font-weight: bold;
}
.info-component .admin-list-container .users-container p.role {
    font-style: italic;
}

.info-component .admin-list-container .users-container .edit-button {
    background: none;
    border: 0;
    overflow: none;
}

.info-component .admin-list-container .users-container .edit-button:hover {
    cursor: pointer;
}

.info-component .admin-list-container .role-selector .selector-button p {
    font-style: italic;
    font-size: 1rem;
}

.info-component .admin-list-container .role-selector .selector-button.isnoteditable {
    cursor: auto;
    cursor: initial;
}

.info-component .admin-list-container .users-container .role-selector li  {
    background: white;
}

.info-message {
    padding: .8rem 1.5rem;
    border-radius: 5px;
}

.info-message.error-true {
    border: 1px solid rgba(172, 0, 0);
    background: rgba(255, 0, 0, 0.274);
}

.info-message.error-false {
    border: 1px solid rgb(29, 172, 0);
    background: rgba(30, 172, 0, 0.274);
}


/* PODCAST & ARTICLES ADMIN */
.content-admin-container a.button-primary {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
}
.content-admin-container .button-primary {
    padding: .8rem 1.5rem;
    margin-top: 1rem;
    width: 7rem;
    display: block;
}

.content-admin-container .button-primary p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 6rem;
}

/* ADD/EDIT PODCAST */

.manage-podcast-container .post-field .button-primary {
    margin-top: 1rem;
    margin-right: 1rem;
}

.manage-podcast-container .post-field h2 {
    margin-bottom: 2rem;
}
#title-input {
    background: none;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    border-radius: 0;
}

#title-input:not([value=""]) ~ .border-bottom {
    border-bottom: 3px solid #AFAFAF;
    border-bottom: 3px solid var(--dark-gray);
    border-radius: 0;
    height: 3px;
    display: block;
    margin: 0;
    width: 0%;
    -webkit-animation: borderBottomInput .2s ease-in both ;
            animation: borderBottomInput .2s ease-in both ;
}
#title-input:focus ~ .border-bottom{
    border-bottom: 3px solid #AFAFAF;
    border-bottom: 3px solid var(--dark-gray);
    border-radius: 0;
    height: 3px;
    display: block;
    margin: 0;
    width: 0%;
    -webkit-animation: borderBottomInput .2s ease-in both ;
            animation: borderBottomInput .2s ease-in both ;
}

#title-input::-webkit-input-placeholder {
    color: #AFAFAF;
    color: var(--dark-gray);
}

#title-input:-ms-input-placeholder {
    color: #AFAFAF;
    color: var(--dark-gray);
}

#title-input::-ms-input-placeholder {
    color: #AFAFAF;
    color: var(--dark-gray);
}

#title-input::placeholder {
    color: #AFAFAF;
    color: var(--dark-gray);
}

#author-input {
    margin-top: 2rem;
}

.post-field .description {
    margin-top: 2rem;
}

.post-field .mini-subtitle {
    color: #AFAFAF;
    color: var(--dark-gray);
    font-style: italic;
    font-size: .8rem;
}

/* CONTENT EDITOR */
.editor-container {
    width: 100%;
    min-height: 200px;
}
.post-field {
    width: 90%;
}

.editor-container .top {
    display: flex;
    align-items: center;
    border: 1px solid #AFAFAF;
    border: 1px solid var(--dark-gray);
    border-bottom: 0;
    margin-top: 2rem;
    background: #e1e4e8;
    background: var(--background-top);
}


.editor-container .top button {
    background: none;
    height: 100%;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    background: #fafbfc;
    background: var(--background-editor);
}

.editor-container .top button:first-child {
    margin-left: 0;
}

.editor-container .top button > svg {
    margin-right: 1rem;
    font-size: 1.5rem;
}

.editor-container .top button:hover { cursor: pointer; }

.editor-container .top button:not(.selected) {
    background: #e1e4e8;
    background: var(--background-top);
}

.editor-container .content {
    height: 100%;
    width: 100%;
}

.editor-container .content .preview-container, .editor-container .content textarea {
    width: 100%;
    background: #fafbfc;
    background: var(--background-editor);
    border-radius: 0;
    border: 1px solid #AFAFAF;
    border: 1px solid var(--dark-gray);
    border-top: 0;
    padding: .5rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
    overflow-y: auto;
    margin: 0;
    display: block;
    height: 200px;
}

/* INPUT AUDIO CONTAINER */
.box-input-container {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.box-input-container .element-input-container {
    margin-right: 2rem;
    margin-top: 1rem;
    background-color: #C13440;
    background-color: var(--primary-color);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem 1rem;
    background: none;
}

.inputfile + label {
	cursor: pointer; 
}

.inputfile + label img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    margin: 1rem;
}

/* MANAGE MEMBERS */
.manage-members-container form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.manage-members-container form input[type=text], .manage-members-container form textarea {
    width: 100%;
    box-sizing: border-box;
}

.manage-members-container .form-container {
    width: 20rem;
}

.manage-members-container .form-container .element-input-container {
    background-color: #C13440;
    background-color: var(--primary-color);
}

.manage-members-container .form-container .element-input-container .inputfile  + label {
    font-size: 1rem;
}

.manage-members-container .form-container .element-input-container .inputfile  + label img {
    border-radius: 50% ;
}

.manage-members-container form .button-primary {
    margin-top: 15px;
    float: right;
}


.manage-members-container .members-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 25px;
    padding: 25px;
}

.manage-members-container .members-list-container > div {
    margin-right: 2rem;
    margin-top: 2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 250px;
    border-radius: 5px;
    background-color: #C13440;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: .2rem .5rem;
}

.manage-members-container .members-list-container > div:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
            filter: brightness(80%)
}

.manage-members-container .members-list-container .img-container {
    height: 100px;
    width: 100px;
    margin: 15px auto;
}

.manage-members-container .members-list-container div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}


/* LIVE SCREEN */
.live-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.live-container > iframe {
    margin: 10px 30px;
    border: 5px solid #C13440;
    border: 5px solid var(--primary-color)
}

/* END LIVE SCREEN */


/* loading container */

.loading-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}

.loading-page-container img {
    height: 100px;
    -webkit-animation: loadingImage 2s linear infinite;
            animation: loadingImage 2s linear infinite;
}

.loading-page-container p {
    margin-top: 100px;
    font-weight: bold;
    font-style: italic;
}

.loading-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

@-webkit-keyframes loadingImage {
    from {
        transform: rotate3d(1, 1, 1, 0deg);
    }
    to {
        transform: rotate3d(1, 1, 1, 360deg);
    }
}
@keyframes loadingImage {
    from {
        transform: rotate3d(1, 1, 1, 0deg);
    }
    to {
        transform: rotate3d(1, 1, 1, 360deg);
    }
}

@-webkit-keyframes borderBottomInput {
    from {
        width: 0;
    }
    to {
        width: 20%;
    }
}

@keyframes borderBottomInput {
    from {
        width: 0;
    }
    to {
        width: 20%;
    }
}

@-webkit-keyframes logoTopbar {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes logoTopbar {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media screen and (max-width: 1120px) {
    header .topbar-container {
        justify-content: space-between;
    }

    header ul {
        display: none;
    }

    header .menu {
        display: block;
        font-size: 2rem;
    }

    /* -- */
    .last-component-container {
        flex-direction: column;
    }

    /* PODCAST */
    
    .podcast-container section, .articles-container section {
        flex-direction: column;
    }
}

@media screen and (max-width: 800px){
    main.home-container .main-info-container {
        /* background-image: url("/src/banners/background_home_mobile.png"); */
        display: none;
    }

    /* COMMU */
    .members-container .member-box {
        flex-direction: column!important;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .members-container .member-box.left .text-container, .members-container .member-box.right .text-container {
        margin: 1rem 0;
    }

    .members-container .text-container {
        max-width: 90%;
    }

    /* CONTACTS */
    input[type="text"], input[type="password"] {
        width: 80%;
    }
    textarea {
        width: 100%;
    }

    .contacts-container .contacts-form .button-container {
        width: 100%;
    }

    /* PODCAST & ARTICLES */
    main.details-container {
        width: 90%;
    }

}


@media screen and (max-width: 610px) {
    main.home-container,
    main.podcast-container,
    main.articles-container,
    main.commu-container,
    main.contacts-container {
        padding: 0 2.5rem;
    }

    header .topbar-container {
        padding: 1.5rem 2.5rem;
    }
    header img {
        height: 40px
    }

    .component-list-container {
        flex-direction: column;
        align-items: center;
    }

    .component-list-container .info-container {
        width: 100%;
        margin-top: 1rem;
        padding: 0;
    }

    .main-info-container h1 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 400px) {
    main.commu-container .main-info-container {
        flex-direction: column;
    }

    main.commu-container .main-info-container > img {
        display: none;
    }

    main.commu-container .main-info-container hr {
        display: none;
    }

    main.commu-container .main-info-container img {
        height: 20%;
    }

    #insta-logo {
        display: block;
        margin: 0;
    }
}
